<template>
  <router-view />
</template>

<script>
export default {
  computed: {
    isIb() {
      return this.$store.state.profile.profileInfo.is_ib
    },
  },
  watch: {
    isIb() {
      if (this.$store.state.profile.profileInfo.is_ib !== undefined && this.$store.state.profile.profileInfo.is_ib === false) {
        this.$router.push({ name: 'ib-register' })
      }
    },
  },
  beforeCreate() {
    if (this.$store.state.profile.profileInfo.is_ib !== undefined && this.$store.state.profile.profileInfo.is_ib === false) {
      this.$router.push({ name: 'ib-register' })
    }
  },
  beforeUpdate() {
    if (this.$store.state.profile.profileInfo.is_ib !== undefined && this.$store.state.profile.profileInfo.is_ib === false) {
      this.$router.push({ name: 'ib-register' })
    }
  },
}
</script>
